import React, { useEffect, useState } from "react";
import { Popover, Space, Avatar, Badge, Typography, Empty } from "antd";
import { BellOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { Services } from "Utilities/network";
import moment from "moment";

const MessageContainer = styled.div`
  margin: 1px;
  padding: 10px;
  border-bottom: solid 2px #f5f7f8;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
`;

const RenderNotifications = ({ notifications = [], unread = 0 }) => {
  const hanldeClick = () => {
    Services.readNotifications();
  };
  if (notifications.length)
    return (
      <Space direction="vertical" style={{ width: "350px", maxHeight: "400px", overflow: "auto" }}>
        {notifications.map((elem) => (
          <MessageContainer
            style={!elem.read ? { background: "#d3e5ffee" } : { background: "#fff" }}
            onClick={() => {
              hanldeClick();
            }}
          >
            <Typography.Text>
              Inventory for hotel {<Typography.Text strong={true}>{elem.inventory.hotel.data.name}</Typography.Text>}{" "}
              from{" "}
              {
                <Typography.Text strong={true}>
                  {moment(elem.inventory.startDate).format("Do MMMM YYYY")}
                </Typography.Text>
              }{" "}
              to{" "}
              {<Typography.Text strong={true}>{moment(elem.inventory.endDate).format("Do MMMM YYYY")}</Typography.Text>}{" "}
              is about to expire soon.
            </Typography.Text>
          </MessageContainer>
        ))}
      </Space>
    );
  return (
    <Space direction="vertical" align="center" style={{ width: "350px" }}>
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    </Space>
  );
};

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [unread, setUnread] = useState([]);

  const fetchList = async () => {
    let resp = await Services.getNotifications();
    setNotifications(resp.notifications);
    setUnread(resp.unread);
  };
  useEffect(() => {
    setTimeout(() => {
      fetchList();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Popover
        placement="bottomRight"
        title={"Notifications"}
        content={RenderNotifications({ notifications, unread })}
        trigger="click"
      >
        <div className="notificaion-bell" style={{ cursor: "pointer" }}>
          <Badge count={unread || 0}>
            <Avatar
              shape="circle"
              size="default"
              icon={<BellOutlined />}
              style={{ backgroundColor: "#028EFF", verticalAlign: "middle" }}
            />
          </Badge>
        </div>
      </Popover>
    </>
  );
};

export default Notifications;
