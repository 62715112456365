import React, { useState, useEffect } from 'react';
import { Progress as AntProgress } from 'antd';
import styled from 'styled-components';

const randomNumberBetween = (start = 0, end = 100) => Math.floor(Math.random() * end) + start;

const ProgressContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
`;

const Progress = styled(AntProgress)`
  top: -0.95rem;

  .ant-progress-inner {
    background-color: transparent;
    border-radius: 0;
  }
  .ant-progress-bg {
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
  }
`;

export default function PageSuspenseFallback() {
  const [progressPercentage, setProgressPercentage] = useState(0);
  useEffect(() => {
    const delayedProgress = setTimeout(() => {
      if (progressPercentage < 99) {
        setProgressPercentage(progressPercentage + randomNumberBetween(0, 100 - progressPercentage));
      }
    }, randomNumberBetween(100, 1000));
    return () => clearTimeout(delayedProgress);
  }, [progressPercentage]);

  return (
    <ProgressContainer>
      <Progress percent={progressPercentage} showInfo={false} status="active" />
    </ProgressContainer>
  );
}
