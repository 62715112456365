import { Suspense, useEffect } from "react";
import "antd/dist/antd.css";
import SuspenseFallback from "./Components/SuspenseFallback";
import { ThemeProvider } from "styled-components";
import theme from "./Configurations/Themes";
import { BrowserRouter as Router } from "react-router-dom";
import { setToken } from "Utilities/network";
import { useHistory } from "react-router-dom";

let CustomRoutes;
CustomRoutes = require("./Routes/index").default;

const AuthProvider = ({ children }) => {
  const history = useHistory();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if (token) {
      setToken(token);
    } else {
      history.push("/");
    }
  }, [history]);
  return children;
};

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Suspense fallback={<SuspenseFallback />}>
            <AuthProvider>
              <CustomRoutes />
            </AuthProvider>
          </Suspense>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
