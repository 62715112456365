import React, { lazy } from "react";
import { Switch, Route } from "react-router-dom";
import { PrivateRoute } from "Components/Route";
import NotFound from "Pages/Default/NotFound";
import MainLayout from "Layouts/MainLayout/MainLayout";

const LoginPage = lazy(() => import(/* webpackChunkName: "LoginPage" */ "Pages/Auth/Login"));

const Dashboard = lazy(() => import(/* webpackChunkName: "Dashboard" */ "Pages/Dashboard"));

const Headers = lazy(() => import(/* webpackChunkName: "Miscellaneous" */ "Pages/CompanySettings/Headers"));
const Citites = lazy(() => import(/* webpackChunkName: "Citites" */ "Pages/CompanySettings/Cities"));
const Tax = lazy(() => import(/* webpackChunkName: "Tax" */ "Pages/CompanySettings/Tax"));

const Hotels = lazy(() => import(/* webpackChunkName: "Hotels" */ "Pages/HotelMaster"));
const HotelInventory = lazy(() => import(/* webpackChunkName: "HotelInventory" */ "Pages/HotelInventory"));
const Sightseeing = lazy(() => import(/* webpackChunkName: "Sightseeing" */ "Pages/SightSeeing"));
const SightseeingCombo = lazy(() => import(/* webpackChunkName: "SightseeingCombo" */ "Pages/SightseeingCombo"));
const Transport = lazy(() => import(/* webpackChunkName: "Transport" */ "Pages/Transport"));
const Meals = lazy(() => import(/* webpackChunkName: "Meals" */ "Pages/Meals"));
const Kitchen = lazy(() => import(/* webpackChunkName: "Kitchen" */ "Pages/Kitchen"));
const DayTrip = lazy(() => import(/* webpackChunkName: "Day Trip" */ "Pages/DayTrips"));
const Miscellaneous = lazy(() => import(/* webpackChunkName: "Miscellaneous" */ "Pages/Miscellaneous"));

const Invoice = lazy(() => import(/* webpackChunkName: "Invoice" */ "Pages/Invoice"));
const Voucher = lazy(() => import(/* webpackChunkName: "Voucher" */ "Pages/Voucher"));
const Itinerary = lazy(() => import(/* webpackChunkName: "Itinerary" */ "Pages/Itinerary"));
const RoomMaster = lazy(() => import(/* webpackChunkName: "RoomMaster" */ "Pages/RoomMaster"));

const Quotation = lazy(() => import(/* webpackChunkName: "Quotation" */ "Pages/Quotation"));

const ShipperRoutes = () => {
  return (
    <Switch>
      <Route path="/" exact={true}>
        <LoginPage />
      </Route>
      <PrivateRoute path="/">
        <MainLayout>
          <Switch>
            <PrivateRoute path="/dashboard" exact={true}>
              <Dashboard />
            </PrivateRoute>
            <PrivateRoute path="/company-settings/cities" exact={true}>
              <Citites />
            </PrivateRoute>
            <PrivateRoute path="/company-settings/tax" exact={true}>
              <Tax />
            </PrivateRoute>
            <PrivateRoute path="/company-settings/headers" exact={true}>
              <Headers />
            </PrivateRoute>

            <PrivateRoute path="/hotels" exact={true}>
              <Hotels />
            </PrivateRoute>
            <PrivateRoute path="/hotel-invetory" exact={true}>
              <HotelInventory />
            </PrivateRoute>

            <PrivateRoute path="/sightseeing" exact={true}>
              <Sightseeing />
            </PrivateRoute>
            <PrivateRoute path="/sightseeing-combo" exact={true}>
              <SightseeingCombo />
            </PrivateRoute>

            <PrivateRoute path="/transport" exact={true}>
              <Transport />
            </PrivateRoute>

            <PrivateRoute path="/meals" exact={true}>
              <Meals />
            </PrivateRoute>

            <PrivateRoute path="/kitchen" exact={true}>
              <Kitchen />
            </PrivateRoute>

            <PrivateRoute path="/daytrip" exact={true}>
              <DayTrip />
            </PrivateRoute>

            <PrivateRoute path="/miscellaneous" exact={true}>
              <Miscellaneous />
            </PrivateRoute>

            <PrivateRoute path="/invoice" exact={true}>
              <Invoice />
            </PrivateRoute>

            <PrivateRoute path="/quotation" exact={true}>
              <Quotation />
            </PrivateRoute>

            <PrivateRoute path="/voucher" exact={true}>
              <Voucher />
            </PrivateRoute>

            <PrivateRoute path="/itinerary" exact={true}>
              <Itinerary />
            </PrivateRoute>

            <PrivateRoute path="/room-master" exact={true}>
              <RoomMaster />
            </PrivateRoute>

            <Route component={NotFound} />
          </Switch>
        </MainLayout>
      </PrivateRoute>
    </Switch>
  );
};

export default ShipperRoutes;
