import axios from "axios";
import Toast from "Components/Toast";

const api = axios.create();

// api.defaults.baseURL = "http://localhost:5000";
api.defaults.baseURL = "https://admin.universalexplorer.ca:5000";
api.defaults.headers.post["Content-Type"] = "application/json";
api.defaults.headers.post["Accept"] = "application/json";

function onError(response) {
  Toast.error("Something Went Wrong");
  return response;
}

function onSuccess(response) {
  if (response.data.code === 401) {
    localStorage.removeItem("token");
    window.location.reload();
  } else {
    return response.data;
  }
}

export const setToken = (token) => {
  if (token) api.defaults.headers.common = { Authorization: `${token}` };
  else delete api.defaults.headers.common["Authorization"];
};

export const Services = {
  test: (data) => api.post("/", data).then(onSuccess, onError),
  login: (data) => api.post("/auth/login", data).then(onSuccess, onError),

  getDashboard: (data) => api.post("/dashboard/data", data).then(onSuccess, onError),

  createHeader: (data) => api.post("/header/create", data).then(onSuccess, onError),
  insertFooter: (data) => api.post("/header/add-footer", data).then(onSuccess, onError),
  listHeader: (data) => api.post("/header/list", data).then(onSuccess, onError),
  deleteHeader: (data) => api.post("/header/delete", data).then(onSuccess, onError),
  listHeaderInput: (data) => api.post("/header/input-list", data).then(onSuccess, onError),

  createCity: (data) => api.post("/city/create", data).then(onSuccess, onError),
  listCity: (data) => api.post("/city/list", data).then(onSuccess, onError),
  deleteCity: (data) => api.post("/city/delete", data).then(onSuccess, onError),
  listCityInput: (data) => api.post("/city/input-list", data).then(onSuccess, onError),

  createTax: (data) => api.post("/tax/create", data).then(onSuccess, onError),
  listTax: (data) => api.post("/tax/list", data).then(onSuccess, onError),
  deleteTax: (data) => api.post("/tax/delete", data).then(onSuccess, onError),
  listTaxInput: (data) => api.post("/tax/input-list", data).then(onSuccess, onError),

  createHotel: (data) => api.post("/hotel/create", data).then(onSuccess, onError),
  listHotel: (data) => api.post("/hotel/list", data).then(onSuccess, onError),
  deleteHotel: (data) => api.post("/hotel/delete", data).then(onSuccess, onError),
  listHotelInput: (data) => api.post("/hotel/input-list", data).then(onSuccess, onError),

  createInventory: (data) => api.post("/inventory/create", data).then(onSuccess, onError),
  listInventory: (data) => api.post("/inventory/list", data).then(onSuccess, onError),
  deleteInventory: (data) => api.post("/inventory/delete", data).then(onSuccess, onError),
  listInventoryInput: (data) => api.post("/inventory/input-list", data).then(onSuccess, onError),

  createSightseeing: (data) => api.post("/sightseeing/create", data).then(onSuccess, onError),
  listSightseeing: (data) => api.post("/sightseeing/list", data).then(onSuccess, onError),
  deleteSightseeing: (data) => api.post("/sightseeing/delete", data).then(onSuccess, onError),
  listSightseeingInput: (data) => api.post("/sightseeing/input-list", data).then(onSuccess, onError),
  listSightseeingInputWithGroup: (data) =>
    api.post("/sightseeing/input-list-with-group", data).then(onSuccess, onError),

  createSightseeingCombo: (data) => api.post("/sightseeing-combo/create", data).then(onSuccess, onError),
  listSightseeingCombo: (data) => api.post("/sightseeing-combo/list", data).then(onSuccess, onError),
  deleteSightseeingCombo: (data) => api.post("/sightseeing-combo/delete", data).then(onSuccess, onError),
  listSightseeingComboInput: (data) => api.post("/sightseeing-combo/input-list", data).then(onSuccess, onError),

  createTransport: (data) => api.post("/transport/create", data).then(onSuccess, onError),
  listTransport: (data) => api.post("/transport/list", data).then(onSuccess, onError),
  deleteTransport: (data) => api.post("/transport/delete", data).then(onSuccess, onError),
  listTransportInput: (data) => api.post("/transport/input-list", data).then(onSuccess, onError),

  createMeal: (data) => api.post("/meal/create", data).then(onSuccess, onError),
  listMeal: (data) => api.post("/meal/list", data).then(onSuccess, onError),
  deleteMeal: (data) => api.post("/meal/delete", data).then(onSuccess, onError),
  listMealInput: (data) => api.post("/meal/input-list", data).then(onSuccess, onError),
  listMealType: (data) => api.post("/meal/list-meal-type", data).then(onSuccess, onError),

  createKitchen: (data) => api.post("/kitchen/create", data).then(onSuccess, onError),
  listKitchen: (data) => api.post("/kitchen/list", data).then(onSuccess, onError),
  deleteKitchen: (data) => api.post("/kitchen/delete", data).then(onSuccess, onError),
  listKitchenInput: (data) => api.post("/kitchen/input-list", data).then(onSuccess, onError),

  createDayTrip: (data) => api.post("/daytrip/create", data).then(onSuccess, onError),
  listDayTrip: (data) => api.post("/daytrip/list", data).then(onSuccess, onError),
  deleteDayTrip: (data) => api.post("/daytrip/delete", data).then(onSuccess, onError),
  listDayTripInput: (data) => api.post("/daytrip/input-list", data).then(onSuccess, onError),

  createMiscellaneous: (data) => api.post("/miscellaneous/create", data).then(onSuccess, onError),
  listMiscellaneous: (data) => api.post("/miscellaneous/list", data).then(onSuccess, onError),
  deleteMiscellaneous: (data) => api.post("/miscellaneous/delete", data).then(onSuccess, onError),
  listMiscellaneousInput: (data) => api.post("/miscellaneous/input-list", data).then(onSuccess, onError),

  createInvoice: (data) => api.post("/invoice/create", data).then(onSuccess, onError),
  listInvoice: (data) => api.post("/invoice/list", data).then(onSuccess, onError),
  deleteInvoice: (data) => api.post("/invoice/delete", data).then(onSuccess, onError),
  listInvoiceInput: (data) => api.post("/invoice/input-list", data).then(onSuccess, onError),

  createItinerary: (data) => api.post("/itinerary/create", data).then(onSuccess, onError),
  listItinerary: (data) => api.post("/itinerary/list", data).then(onSuccess, onError),
  deleteItinerary: (data) => api.post("/itinerary/delete", data).then(onSuccess, onError),

  createRoomMaster: (data) => api.post("/roommaster/create", data).then(onSuccess, onError),
  listRoomMaster: (data) => api.post("/roommaster/list", data).then(onSuccess, onError),
  deleteRoomMaster: (data) => api.post("/roommaster/delete", data).then(onSuccess, onError),

  createVoucher: (data) => api.post("/voucher/create", data).then(onSuccess, onError),
  listVoucher: (data) => api.post("/voucher/list", data).then(onSuccess, onError),
  deleteVoucher: (data) => api.post("/voucher/delete", data).then(onSuccess, onError),
  listVoucherInput: (data) => api.post("/voucher/input-list", data).then(onSuccess, onError),

  createQuotation: (data) => api.post("/quotation/create", data).then(onSuccess, onError),
  listQuotation: (data) => api.post("/quotation/list", data).then(onSuccess, onError),
  deleteQuotation: (data) => api.post("/quotation/delete", data).then(onSuccess, onError),
  listQuotationInput: (data) => api.post("/quotation/input-list", data).then(onSuccess, onError),
  copyQuotation: (data) => api.post("/quotation/copy", data).then(onSuccess, onError),
  approveRequestQuotation: (data) => api.post("/quotation/approve-request", data).then(onSuccess, onError),
  releaseInventory: (data) => api.post("/quotation/release-inventory", data).then(onSuccess, onError),
  revertReduce: (data) => api.post("/quotation/revert-reduce", data).then(onSuccess, onError),

  getNotifications: (data) => api.post("/list-notification", data).then(onSuccess, onError),
  readNotifications: (data) => api.post("/mark-read", data).then(onSuccess, onError),
};
