import { useEffect, useState } from "react";
import { Layout, Menu, Popover } from "antd";
import {
  CaretDownOutlined,
  DashboardOutlined,
  SettingOutlined,
  HomeOutlined,
  EyeOutlined,
  CarOutlined,
  AliyunOutlined,
  ShopOutlined,
  NodeIndexOutlined,
  PlusOutlined,
  FileTextOutlined,
  DollarCircleOutlined,
  InsertRowBelowOutlined,
  CalendarOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import "./MainLayout.css";
import Logo from "Images/logo-mini.png";
import { Link } from "react-router-dom";
import Notifications from "Pages/Notifications";
import { useHistory } from "react-router";

const { SubMenu } = Menu;
const { Header, Content, Sider } = Layout;

const MainLayout = ({ children }) => {
  const history = useHistory();
  const [openKeys, setOpenKeys] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState("/dashboard");

  useEffect(() => {
    const openKeys = sessionStorage.getItem("openKeys");
    const selectedMenu = sessionStorage.getItem("selectedMenu");
    setOpenKeys(JSON.parse(openKeys));
    setSelectedMenu(selectedMenu);
  }, []);

  const handleClick = (menu) => {
    history.push(menu.key);
  };

  const updateOpenMenuKeys = (openKeys) => {
    setOpenKeys(openKeys);
    sessionStorage.setItem("openKeys", JSON.stringify(openKeys));
  };

  const updateSelectedMenu = (selectedMenu) => {
    setSelectedMenu(selectedMenu.key);
    sessionStorage.setItem("selectedMenu", selectedMenu.key);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    sessionStorage.clear();
    history.replace("/");
  };

  return (
    <Layout id="main-layout">
      <Header className="header">
        <Link
          to="/dashboard"
          onClick={() => {
            setSelectedMenu("/dashboard");
          }}
        >
          <img className="logo" src={Logo} alt="Logo" />
        </Link>
        <div className="header-right">
          <div style={{ display: "flex", justifyContent: "space-between", alignContent: "center" }}>
            <div>
              <Notifications />
            </div>
            <div style={{ marginLeft: "20px", fontSize: "22px" }}>
              <Popover
                placement="bottomRight"
                title={""}
                content={
                  <Menu>
                    <Menu.Item
                      onClick={() => {
                        handleLogout();
                      }}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                }
                trigger="click"
              >
                <CaretDownOutlined style={{ color: "#fff" }} />
              </Popover>
            </div>
          </div>
        </div>
      </Header>
      <Content style={{ padding: "0 0px" }}>
        <Layout className="site-layout-background" style={{ padding: "0px 0", marginBottom: "20px" }}>
          <Sider className="site-layout-background" width={200}>
            <Menu
              id="style-3"
              mode="inline"
              onClick={handleClick}
              defaultSelectedKeys={["/dashboard"]}
              defaultOpenKeys={[]}
              openKeys={openKeys}
              selectedKeys={selectedMenu}
              onOpenChange={updateOpenMenuKeys}
              onSelect={updateSelectedMenu}
              style={{
                height: "80vh",
                overflow: "hidden",
                overflowY: "auto",
              }}
            >
              <Menu.Item key="/dashboard" icon={<DashboardOutlined />}>
                Dashboard
              </Menu.Item>
              <Menu.Item key="/quotation" icon={<FileTextOutlined />}>
                Quotation
              </Menu.Item>

              <SubMenu key="company-settings" icon={<SettingOutlined />} title="Company Settings">
                <Menu.Item key="/company-settings/cities">City Master</Menu.Item>
                <Menu.Item key="/company-settings/tax">Tax</Menu.Item>
                <Menu.Item key="/company-settings/headers">Headers</Menu.Item>
              </SubMenu>

              <SubMenu key="sub2" icon={<HomeOutlined />} title="Hotel">
                <Menu.Item key="/hotels">Hotel Master</Menu.Item>
                <Menu.Item key="/hotel-invetory">Hotel Inventory</Menu.Item>
              </SubMenu>

              <SubMenu key="sightseeing" icon={<EyeOutlined />} title="Sightseeing">
                <Menu.Item key="/sightseeing">Sightseeing</Menu.Item>
                <Menu.Item key="/sightseeing-combo">Sightseeing Combo</Menu.Item>
              </SubMenu>

              <Menu.Item key="/transport" icon={<CarOutlined />}>
                Transport
              </Menu.Item>

              <Menu.Item key="/meals" icon={<AliyunOutlined />}>
                Meals
              </Menu.Item>

              <Menu.Item key="/kitchen" icon={<ShopOutlined />}>
                Kitchen
              </Menu.Item>

              <Menu.Item key="/daytrip" icon={<NodeIndexOutlined />}>
                Day Trips
              </Menu.Item>

              <Menu.Item key="/miscellaneous" icon={<PlusOutlined />}>
                Miscellaneous
              </Menu.Item>
              <Menu.Item key="/voucher" icon={<InsertRowBelowOutlined />}>
                Voucher
              </Menu.Item>
              <Menu.Item key="/invoice" icon={<DollarCircleOutlined />}>
                Invoice
              </Menu.Item>
              <Menu.Item key="/itinerary" icon={<CalendarOutlined />}>
                Itinerary
              </Menu.Item>
              <Menu.Item key="/room-master" icon={<UnorderedListOutlined />}>
                Room Master
              </Menu.Item>
            </Menu>
          </Sider>
          <Content style={{ padding: "0 24px", minHeight: "calc(100vh - 84px)" }}>{children}</Content>
        </Layout>
      </Content>
    </Layout>
  );
};

export default MainLayout;
